import React, { Component } from "react";
import logo from "./Logo.svg";
import "./App.css";

class App extends Component {
  render() {
    return (
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
          <p style={{ lineHeight: "34px" }}>
            <b>Eugene Shulman P. Eng</b> | Principal Consultant <br />
            ES P&C Consulting Inc.
          </p>
          <p style={{ fontSize: 14, lineHeight: "25px" }}>
            Tel: 905-695-3950 | Cell: 416- 562-5733
            <br /> E-mail:{" "}
            <a href="mailto:eshulman@espandc.com">eshulman@espandc.com</a>
            <br /> Address: 236 Farrell Rd., Maple, ON, Canada L6A 4W5
          </p>
        </header>
      </div>
    );
  }
}

export default App;
